<template>
  <div>
    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('entries') }}</label>
          </b-col>

          <b-col
              cols="12"
              md="2"
          >
            <div class="d-flex align-items-center justify-content-end">
              <v-select
                  v-model="dateRange"
                  class="w-100"
                  :options="dateRangeOptions"
                  :reduce="val => val.value"
                  :placeholder="$t('Date range')"
              />

            </div>
          </b-col>
          <!-- Search -->
          <b-col
              cols="12"
              md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search...')"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refTendersListTable"
          class="position-relative"
          :items="fetchTenders"
          responsive
          :fields="tableCols"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          :empty-text="$t('No matching records found')"
          :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(show_details)="row">
          <b-button
              class="btn-icon"
              variant="outline-secondary"
              size="sm"
              @click="row.toggleDetails"
          >
            <feather-icon
                v-if="!row.detailsShowing"
                icon="EyeIcon"
            />
            <feather-icon
                v-if="row.detailsShowing"
                icon="EyeOffIcon"
            />
          </b-button>
        </template>

        <template #row-details="row">
          <b-card>
            <b-row class="mb-2">
              <b-col
                  md="7"
                  class="mb-1"
              >
                <strong class="text-capitalize">{{ $t('contract authority name') }}
                  : </strong>{{ row.item.contract_authority_name }}
              </b-col>
              <b-col
                  md="5"
                  class="mb-1"
              >
                <strong class="text-capitalize">{{ $t('address') }} : </strong><span v-if="row.item.address"
              >{{ row.item.address }}, {{
                  row.item.postal_code
                }} {{ row.item.town }}
                </span>
              </b-col>
            </b-row>

            <b-row class="mb-2">
              <b-col
                  md="10"
                  class="mb-1"
              >
                <strong class="text-capitalize">{{ $t('description') }} : </strong>{{ row.item.description }}
              </b-col>
            </b-row>

            <b-row class="mb-2">
              <b-col
                  md="4"
                  class="mb-1"
              >
                <strong class="text-capitalize">{{ $t('publication number') }} : </strong>{{
                  row.item.reference_number
                }}
              </b-col>

              <b-col
                  md="4"
                  class="mb-1"
              >
                <strong class="text-capitalize">{{ $t('publication date') }} : </strong>{{ row.item.publication_date }}
              </b-col>

              <b-col
                  md="4"
                  class="mb-1"
              >
                <strong class="text-capitalize">{{ $t('submission deadline') }}
                  : </strong>{{ row.item.submission_deadline }}
              </b-col>
            </b-row>

            <b-row class="mb-2">
              <b-col
                  md="4"
                  class="mb-1"
              >
                <strong class="text-capitalize">{{ $t('cpvs') }} : </strong>{{ row.item.main_cpvs }}
              </b-col>

              <b-col
                  md="4"
                  class="mb-1"
              >
                <strong class="text-capitalize">{{ $t('contract type') }} : </strong> {{ row.item.contract_type }}
              </b-col>

              <b-col
                  md="4"
                  class="mb-1"
              >
                <strong class="text-capitalize">{{ $t('document type') }} : </strong> {{ row.item.document_type }}
              </b-col>
            </b-row>

            <b-row>
              <b-col
                  md="4"
                  class="mb-1"
              >
                <strong class="text-capitalize">{{ $t('contact person') }} : </strong>{{ row.item.contact_person }}
              </b-col>

              <b-col
                  md="4"
                  class="mb-1"
              >
                <strong class="text-capitalize">Email : </strong>
                <b-link
                    :href="'mailto:' + row.item.contact_email"
                    target="_blank"
                >
                  {{ row.item.contact_email }}
                </b-link>
              </b-col>

              <b-col
                  md="4"
                  class="mb-1"
              >
                <strong class="text-capitalize">{{ $t('phone') }} : </strong>{{ row.item.contact_phone }}
              </b-col>
            </b-row>

            <b-row class="mb-2">
              <b-col
                  md="3"
                  class="mb-1"
              >
                <span><strong class="text-capitalize">URL :</strong></span><span>
                  <b-link
                      :href="row.item.url"
                      target="_blank"
                  >
                    <feather-icon
                        :id="`invoice-row-${row.item.url}-preview-icon`"
                        icon="ExternalLinkIcon"
                        size="16"
                        class="mx-1"
                    />
                  </b-link>
                </span>
              </b-col>
            </b-row>

            <b-button
                size="sm"
                variant="outline-secondary"
                @click="row.toggleDetails"
            >
              <feather-icon
                  icon="EyeOffIcon"
              />
              {{ $t('Hide Details') }}
            </b-button>
          </b-card>
        </template>

        <!-- Column: Publication date -->
        <template #cell(publication_date)="data">
          <span class="text-nowrap ">
            {{
              new Date(data.value).toLocaleDateString('sl-si', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
              })
            }}
          </span>
        </template>

        <!-- Column: Deadline date -->
        <template #cell(submission_deadline)="data">
          <span class="text-nowrap">
            {{
              data.value ?
                  new Date(data.value).toLocaleDateString('sl-si', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric'
                  })

                  : ''
            }}
          </span>
        </template>

        <!-- Column: url -->
        <template #cell(url)="data">
          <b-link
              :href="data.value"
              class="font-weight-bold d-block text-nowrap"
              target="_blank"
          >
            <feather-icon
                :id="`invoice-row-${data.item.id}-preview-icon`"
                icon="ExternalLinkIcon"
                size="16"
                class="mx-1"
            />
          </b-link>
        </template>

        <!-- Column: Title -->
        <template #cell(tender_title)="data">
          <div
              class="mb-1 mt-1"
          >
            {{ data.item.title }}
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{
                $t('Showing {from} to {to} of {of} entries', {
                  from: dataMeta.from,
                  to: dataMeta.to,
                  of: dataMeta.of
                })
              }}</span>
          </b-col>

          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="currentPage"
                :total-rows="totalTenders"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BLink,
  BPagination, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useTendersList from '@/views/pages/tenders/tenders-list/useTendersList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BLink,
    BButton,
    vSelect,
  },
  computed: {
    tableCols() {
      return [
        {
          key: 'show_details',
          label: this.$t('show details')
        },
        {
          key: 'tender_title',
          label: this.$t('tender title'),
          sortable: true
        },
        {
          key: 'document_type',
          label: this.$t('document type'),
          sortable: false
        },
        {
          key: 'contract_authority_name',
          label: this.$t('contract authority name'),
          sortable: false
        },
        {
          key: 'publication_date',
          label: this.$t('publication date'),
          sortable: true
        },
        {
          key: 'submission_deadline',
          label: this.$t('submission deadline'),
          sortable: false
        },
        {
          key: 'url',
          sortable: false
        },
      ]
    },
    dateRangeOptions() {
      return [
        {
          value: 'today',
          label: this.$t('Today')
        },
        {
          value: 'yesterday',
          label: this.$t('Yesterday')
        },
        {
          value: 'last_7_days',
          label: this.$t('Last 7 days')
        },
        {
          value: 'last_14_days',
          label: this.$t('Last 14 days')
        },
        {
          value: 'last_month',
          label: this.$t('Last month')
        },
        {
          value: 'all',
          label: this.$t('All')
        },
      ]
    },
  },
  setup() {
    const isAddNewClientSidebarActive = ref(false)

    const selectedTenderData = ref(false)

    const {
      fetchTenders,
      tableColumns,
      perPage,
      currentPage,
      totalTenders,
      dataMeta,
      perPageOptions,
      searchQuery,
      dateRange,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      refTendersListTable,
    } = useTendersList()

    return {
      // Sidebar
      isAddNewClientSidebarActive,

      fetchTenders,
      tableColumns,
      perPage,
      currentPage,
      totalTenders,
      dataMeta,
      perPageOptions,
      searchQuery,
      dateRange,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      selectedTenderData,

      refTendersListTable,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
